import { useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Box, Grid } from '@mui/material';
import { PlayerDatabaseType } from '../../playerdatabase/type';
import { PlayerRankingType, TiersState, UpdateRankingType } from '../type';
import { StyledCard } from '../../../common/component/Card';
import DragPlayerList from './DragPlayerList';
import DraggableRankDataGrid from './DraggableRankDataGrid';
import { Header3 } from '../../../common/component/Text';

type Props = {
  rank: PlayerRankingType[];
  playerList: PlayerDatabaseType[];
  updatePlayerPos: (playerInfo: UpdateRankingType) => void;
  isLoading: boolean;
};

const DragAndDropRanking = ({ updatePlayerPos, rank, playerList, isLoading }: Props) => {
  const [tiers, setTiers] = useState<TiersState>({
    tier1: rank.filter((player) => player.tier === 1),
    tier2: rank.filter((player) => player.tier === 2),
    tier3: rank.filter((player) => player.tier === 3),
    tier4: rank.filter((player) => player.tier === 4),
    tier5: rank.filter((player) => player.tier === 5),
    playerList: playerList,
  });

  const handleRankingUpdate = (
    tier: number,
    playerId: number | string,
    newIndex: number,
    sourceTier: string,
  ) => {
    const precedingPlayersCount = Object.keys(tiers)
      .filter(
        (key) =>
          key.startsWith('tier') && parseInt(key.replace('tier', '')) < tier && key !== sourceTier,
      )
      .reduce(
        (count, key) => count + (tiers[key as keyof TiersState] as PlayerRankingType[]).length,
        0,
      );

    const sourcePlayersCount =
      sourceTier === `tier${tier}`
        ? newIndex + 1
        : (tiers[sourceTier as keyof TiersState] as PlayerRankingType[]).length;

    const newRank = precedingPlayersCount + sourcePlayersCount;

    updatePlayerPos({ ranking: newRank, tier, playerId: playerId.toString() });
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination || destination.droppableId === 'playerList') return;

    const sourceTier = source.droppableId as keyof TiersState;
    const sourceList = Array.from((tiers as any)[sourceTier]) as PlayerRankingType[];

    const destTier = destination.droppableId as keyof TiersState;
    const destList = Array.from((tiers as any)[destTier]) as PlayerRankingType[];

    const [movedPlayer] = sourceList.splice(source.index, 1);

    const newTier = parseInt(destTier.replace('tier', ''));
    const playerId = typeof movedPlayer.id === 'number' ? movedPlayer.id : parseInt(movedPlayer.id);
    destList.splice(destination.index, 0, {
      ...movedPlayer,
      tier: newTier,
      rank: destination.index + 1,
    });

    setTiers((prev) => ({
      ...prev,
      [sourceTier]: sourceList,
      [destTier]: destList,
    }));
    handleRankingUpdate(newTier, playerId, destination.index, sourceTier);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container columnSpacing={'20px'}>
        <Grid item xs={9}>
          <Grid container style={{ pointerEvents: isLoading ? 'none' : 'all' }}>
            <Grid item xs={12}>
              <Grid overflow={'auto'} gap={'30px'} display={'flex'}>
                {Object.keys(tiers)
                  .filter((key) => key !== 'playerList')
                  .map((tierKey, idx) => (
                    <Droppable droppableId={tierKey} key={tierKey}>
                      {(provided) => (
                        <StyledCard>
                          <Box ref={provided.innerRef} {...provided.droppableProps} width={270}>
                            <Grid width={'100%'}>
                              <Header3 textsize={'16px'} textAlign={'center'}>
                                {`Tier ${idx + 1}`}
                              </Header3>
                            </Grid>
                            <Grid item xs={12}>
                              <DraggableRankDataGrid
                                providerPlaceholder={provided.placeholder}
                                tiers={tiers[tierKey as keyof typeof tiers] as PlayerRankingType[]}
                              />
                            </Grid>
                          </Box>
                        </StyledCard>
                      )}
                    </Droppable>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <DragPlayerList playerList={tiers.playerList} />
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

export default DragAndDropRanking;
