import { Grid, IconButton, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { FC } from 'react';
import { CompactTag } from '../../../common/component/Text';
import { TAG_COLOR, TEXT_COLOR_INFO_TYPE, TOOLTIP_COLOR } from '../../../theme/color';
import { styled } from '@mui/material/styles';
import { FlagTooltipType } from '../../header/type';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AiOutlineSetting } from 'react-icons/ai';

type Props = {
  data?: FlagTooltipType;
  setRosterModalStatus: () => void;
};

export const CustomStyleTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: TOOLTIP_COLOR.background,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 'calc(100% - 30px)',
    fontSize: theme.typography.pxToRem(8),
    border: `1px solid ${TOOLTIP_COLOR.border}`,
    zIndex: 9999,
  },
}));

const CustomTooltip: FC<Props> = ({ data, setRosterModalStatus }) => {
  return (
    <Grid container flexWrap={'nowrap'} spacing={'20px'} alignItems={'center'} width={'100%'}>
      <Grid item>
        <Typography fontWeight={'bold'} fontSize={'12px'}>
          {data?.teamName || 'Hello'}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container flexWrap={'nowrap'} alignItems={'center'} columnSpacing={'20px'}>
              <Grid item xs={12}>
                <Box display={'flex'} gap={'5px'} flexWrap={'nowrap'} alignItems={'center'}>
                  <Box>
                    <Typography fontSize={'12px'} fontWeight={'bold'}>
                      {data?.currentYear}
                    </Typography>
                  </Box>

                  <Box display={'flex'} gap={'5px'} flexWrap={'wrap'}>
                    {data?.currentYearPickDict.map((item) => (
                      <CompactTag
                        gap={'5px'}
                        key={item.pick}
                        style={{ padding: '1px 1px 0px 1px', lineHeight: 0, height: 20 }}
                        display={'flex'}
                        alignItems={'center'}
                        textsize={'12px'}
                        textAlign={'center'}
                        bgcolor={'transparent'}
                        fontcolor={
                          item.pickStatus === 'Used'
                            ? TEXT_COLOR_INFO_TYPE.error
                            : item.clubPickNumber > Number(data.currentYearRosterSpots)
                            ? TAG_COLOR.disabled
                            : TAG_COLOR.success
                        }
                        minWidth={15}
                      >
                        {item.pick}
                      </CompactTag>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box>
                  <Box display={'flex'} gap={'30px'} alignItems={'center'}>
                    <Typography fontWeight={'bold'} fontSize={'12px'}>
                      Pts: {data?.currentYearsPts}
                    </Typography>
                    <Typography fontWeight={'bold'} fontSize={'12px'}>
                      Useable: {data?.currentYearPtsRemaining}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={'5px'}>
            <Grid container flexWrap={'nowrap'} alignItems={'center'} columnSpacing={'20px'}>
              <Grid item xs={12}>
                <Box display={'flex'} gap={'5px'} flexWrap={'nowrap'} alignItems={'center'}>
                  <Box>
                    <Typography fontWeight={'bold'} fontSize={'12px'}>
                      {data?.nextYear}
                    </Typography>
                  </Box>
                  <Box display={'flex'} gap={'5px'} flexWrap={'wrap'}>
                    {data?.nextYearPicks.split(',').map((item) => (
                      <CompactTag
                        gap={'5px'}
                        key={item}
                        display={'flex'}
                        alignItems={'center'}
                        textsize={'8px'}
                        textAlign={'center'}
                        style={{ padding: '1px 1px 0px 1px', lineHeight: 0, height: 20 }}
                        bgcolor={'transparent'}
                        fontcolor={TAG_COLOR.disabled}
                      >
                        {item}
                      </CompactTag>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box>
                  <Box display={'flex'} gap={'30px'} alignItems={'center'}>
                    <Typography fontWeight={'bold'} fontSize={'12px'}>
                      Pts : {data?.nextYersPts}
                    </Typography>
                    <Typography fontWeight={'bold'} fontSize={'12px'}>
                      Useable : {data?.nextYearPtsRemaining}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box width={'150px'} pl={'30px'} borderLeft={'1px grey solid'}>
          <IconButton onClick={setRosterModalStatus} title='Edit List Spots' color={'info'}>
            <AiOutlineSetting fontSize={'12px'} />{' '}
            <Typography ml={'10px'} fontSize={'12px'}>
              Edit List Spots
            </Typography>
          </IconButton>
          <Typography fontWeight={'bold'} fontSize={'12px'} textAlign={'left'}>
            List Spots Total: {data?.currentYearRosterSpots}
          </Typography>

          <Typography fontWeight={'bold'} fontSize={'12px'} textAlign={'left'}>
            List Spots Availaible: {data?.currentYearPickRemaining}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CustomTooltip;
